define("discourse/plugins/discourse-mermaid/initializers/discourse-mermaid", ["exports", "discourse/lib/load-script", "discourse/lib/plugin-api"], function (_exports, _loadScript, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "discourse-mermaid",
    initialize(container) {
      (0, _pluginApi.withPluginApi)("0.8.22", api => {
        api.decorateCooked($elem => {
          const $mermaid = $elem.find(".mermaid");
          if ($mermaid.length) {
            (0, _loadScript.default)("/plugins/discourse-mermaid/javascripts/mermaid.min.js").then(() => {
              mermaid.init(undefined, $mermaid);
            });
          }
        });
      });
    }
  };
});